<template>
  <div :class="{ 'c-dark-theme': $store.getters.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">403</h1>
              <h4 class="pt-3">{{ $t('pagePermissionDenied.title') }}</h4>
              <p class="text-muted">{{ $t('pagePermissionDenied.subtitle') }}</p>
              <CLink
                class="text-primary"
                :to="$store.getters['user/homepage']"
              >
                {{ $t('pagePermissionDenied.link') }}
              </CLink>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'PagePermissionDenied'
}
</script>
